;(function () {
	const showmoreClick = document.querySelectorAll('.showmore')

	Array.prototype.forEach.call(showmoreClick, function (showmore) {
		if (showmore) {
			showmore.addEventListener('click', function () {
				this.parentNode.classList.toggle('show-text')
				this.classList.toggle('is-more')

				const parent = this.closest('.download-grid--list')
				if (parent) {
					parent.classList.toggle('is-less')
				}
			})
		}
	})
})()
